

export const AwaitFrom = [
    {
        title:"北苑二楼",
        num:0
    },{
        title:"北苑三楼",
        num:0
    },{
        title:"南苑二楼",
        num:0
    },
]

export const AwaitTo = [
    {
        title:"南七",
        num:0
    },{
        title:"南八",
        num:0
    },{
        title:"南九",
        num:0
    },
]
