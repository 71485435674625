

export function setHTML (src:any):any {
    let div = document.createElement("div")
    div.className = "BikeSucMapBike"
    let img = document.createElement("img")
    img.src = src
    img.className = "BikeSucMapBikeImg"
    div.appendChild(img)
    return div
}


export function setBabel(content:string) {
    let div = document.createElement("div")
    let span = document.createElement("span")
    span.innerText = content
    div.appendChild(span)
    return div
}
