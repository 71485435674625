import {bikeDisPathSuc} from "../../../Api/ShopAdmin";
import Storage from "@/util/Storage"

// 骑手信息
export function getBikeSuc(id:string|number) { // ,{ userId:id }
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve(bikeDisPathSuc({ userId:userId ,token },{ sendUserId:id }).then(res=>{
        let data:any = { ...res.data,getSth:[],served:[] }
        // 代取货
        if ( "arrive" in res.data && res.data.arrive.length ){
            let getSth:any[] = []
            res.data.arrive.forEach((item:any,index:number)=>{
                getSth.push({
                    title:item.buildNumberName,
                    num:item.orderCount || 0,
                    ...item
                })
            })
            data["getSth"] = getSth
        }
        // 待送达
        if ( "pickedUp" in res.data && res.data.pickedUp.length ){
            let served:any[] = []
            res.data.pickedUp.forEach((item:any,index:number)=>{
                served.push({
                    title:item.area || '',
                    num:item.orderCount || 0,
                    ...item
                })
            })
            data["served"] = served
        }
        return data
    }))
}
